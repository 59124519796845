<template>
  <div :class="prefixCls" @click="onClick">
    <span :class="prefixCls + '__text'">
      <slot></slot>
    </span>
  </div>
</template>

<script>
const prefixCls = 'hai-button'
export default {
  name: 'HaiButton',
  data() {
    return {
      prefixCls
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
$prefixCls: 'hai-button';

.#{$prefixCls} {
  width: 220px;
  height: 60px;

  background-image: url('~@/assets/images/button-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  display: inline-flex;
  cursor: pointer;
  &__text {
    font-size: 24px;
    display: inline-block;
    color: #5391f9;
    transition: color 0.3s;
    width: 100%;
    height: 100%;
    line-height: 60px;
    &:hover {
      color: #fff;
    }
  }
}
</style>
