import { API } from './api-list'
import { $post, $get } from '../assets/js/http'
// 上传
export function uploadImg(data, header) {
  return $post(API.upload, data, header)
}
export function getStyles(data, header) {
  return $get(API.getStyles, data, header)
}
// 提交
export function create_lyrics_work(data, header) {
  return $post(API.ly_work, data, header)
}
// 无声水印
export function GetWatermark(data) {
  return $post(API.watermark, data)
}
export function GetWatermarkStatus(id) {
  return $get(API.watermark, id)
}
// 无声水印检测
export function GetWatermarkDetect(data) {
  return $post(API.watermarkDetect, data)
}

// 无声水印检测进度
export function GetWatermarkDetectResult(data) {
  return $get(API.watermarkDetect, data)
}
// 人声分离
export function GetSegmentation(data) {
  return $post(API.segmentationVoice, data)
}

// 人声分离结果
export function GetSegmentationResult(data) {
  return $get(API.segmentationVoiceResult, data)
}

// 智能标签
export function GetSmartTags(data) {
  return $post(API.smartTags, data)
}

// 智能标签
export function GetSmartTagsResult(data) {
  return $get(API.smartTags, data)
}

// ai提示
export function GetDynamicLyrics(data) {
  return $post(API.dynamicLyric, data)
}
export function GetFirstLyrics(data) {
  return $get(API.firstLyric, data)
}
// 切词
export function GetWords(data) {
  return $post(API.segmentWord, data)
}
// 换词
export function GetChangeWords(data) {
  return $post(API.recommendWords, data)
}
// 押韵
export function GetMagicWords(data) {
  return $post(API.rhymeWords, data)
}
// 作曲
export function GetComposer(data) {
  return $post(API.aiCreateMusic, data)
}
// 检测输入内容
export function GetDirty(data) {
  return $get(API.dirty, data)
}
