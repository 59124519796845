const baseUrl =
  process.env.VUE_APP_ENV === 'local'
    ? 'https://api-hischool-local.xingzheai.cn'
    : process.env.VUE_APP_ENV === 'production'
    ? 'https://api-hischool.xingzheai.cn'
    : 'https://api-hischool-dev.xingzheai.cn'

// const baseUrl = 'http://172.20.11.8:8000'

// https://api-hischool.xingzheai.cn(正式)
// const baseUrl = 'https://192.168.1.51:4000/'
// const baseUrl = 'https://172.17.253.1:4000/'
// const baseUrl = 'http://local.api-hischool.cn:8000'
// const mainUrl = 'https://api-hischool-test.xingzheai.cn'

export const API = {
  getStyles: `${baseUrl}/api/ai_lyric/`,
  firstLyric: `${baseUrl}/api/lyric/first_lyric/`,
  dynamicLyric: `${baseUrl}/api/lyric/v1/dynamic/`,
  segmentWord: `${baseUrl}/api/lyric/v1/words/segment/`,
  recommendWords: `${baseUrl}/api/lyric/v1/words/recommend/`,
  rhymeWords: `${baseUrl}/api/lyric/v1/words/rhyme/`,
  upload: `${baseUrl}/api/work/upload_img/`,
  ly_work: `${baseUrl}/api/work/create_lyrics_work/`,
  dirty: `${baseUrl}/api/dirty/`,

  smartTags: `${baseUrl}/hifive_ai/api/ai_music_classification_unified`,
  aiCreateMusic: `${baseUrl}/compose`,
  segmentationVoice: `${baseUrl}/voice_segmentation_api`,
  segmentationVoiceResult: `${baseUrl}/voice_segmentation_api_result`,
  watermark: `${baseUrl}/demo/v1/watermark`,
  watermarkDetect: `${baseUrl}/demo/v1/watermark/detect`
}

export const url = {
  baseUrl: baseUrl
  // mainUrl: mainUrl,
}
