<template>
  <Layout :title="success ? $t('smartTags.success') : $t('smartTags.fail')">
    <div :class="prefixCls">
      <div :class="prefixCls + '__result'" v-if="success">
        <div
          :class="prefixCls + '__item'"
          v-for="key in sortList[$i18n.locale]"
          :key="key"
        >
          <p :class="prefixCls + '__item_text'">
            {{ key }}
          </p>
          <div :class="prefixCls + '__item_container'">
            <div
              :class="prefixCls + '__item_ball'"
              v-for="item in handleData[key]"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <!--    无结果    -->
      <div :class="prefixCls + '__empty'" v-else>
        <h2>{{ $t('smartTags.failReason') }}</h2>
        <h5>{{ $t('smartTags.possibleReason') }}</h5>
        <p>{{ $t('smartTags.reason1') }}</p>
        <p>{{ $t('smartTags.reason2') }}</p>
      </div>
      <div :class="prefixCls + '__footer'">
        <HaiButton @click="$router.back()" v-if="!success">{{
          $t('tryAgain')
        }}</HaiButton>
        <HaiButton @click="$router.push({ path: '/' })">{{
          $t('backHome')
        }}</HaiButton>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import HaiButton from '@/components/HaiButton'

const prefixCls = 'smartTag-result-page'
export default {
  name: 'SmartTagsResult',
  components: {
    Layout,
    HaiButton
  },

  data() {
    return {
      prefixCls,
      success: false,
      data: {},
      sortList: {
        ch: ['流派', '乐器', '情绪', '场景'],
        en: ['genre', 'instrument', 'mood', 'scene']
      }
    }
  },

  computed: {
    handleData() {
      return this.data[this.$i18n.locale]
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      try {
        const localData = window.sessionStorage.getItem('smart_tag_data')
        const data = JSON.parse(localData)
        if (data.result.result) {
          this.data = data.result.result
          this.success = true
        }
      } catch (e) {
        this.success = false
      }
    }
  }
}
</script>

<style lang="scss">
$prefixCls: 'smartTag-result-page';
.#{$prefixCls} {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &__result {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    height: 500px;
  }
  &__empty {
    width: 600px;
    margin-top: 170px;
    padding: 20px;
    color: #68e7ed;
    background-color: #143045;
    border-top: 1px #fff solid;
    h2 {
      font-size: 24px;
      text-align: center;
      margin-bottom: 20px;
    }
    p,
    h5 {
      font-size: 16px;
      text-align: left;
      line-height: 32px;
    }
  }
  &__item {
    width: 360px;
    height: 220px;
    border-radius: 8px;
    background-color: #001636;
    border: 1px #358599 solid;
    overflow: hidden;
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    &_text {
      line-height: 24px;
      font-size: 24px;
      color: #fff;
      margin: 20px 0;
      flex-shrink: 0;
    }
    &_container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      justify-items: center;
    }
    &_ball {
      display: inline-block;
      padding: 0 30px;
      line-height: 40px;
      border: 1px #fff solid;
      color: #fff;
      font-size: 20px;
      border-radius: 10px;
      text-align: center;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        background: #5391f9 !important;
        color: #fff !important;
      }
    }
    &:nth-of-type(1) {
      .#{$prefixCls}__item_text {
        color: #68e7ed;
      }
      .#{$prefixCls}__item_ball {
        border-color: #68e7ed;
        background: rgba(83, 145, 249, 0.2);
        color: #68e7ed;
      }
    }
    &:nth-of-type(2) {
      .#{$prefixCls}__item_text {
        color: #2abff1;
      }
      .#{$prefixCls}__item_ball {
        background: rgba(83, 145, 249, 0.2);
        border-color: #2abff1;
        color: #2abff1;
      }
    }
    &:nth-of-type(3) {
      .#{$prefixCls}__item_text {
        border-color: #5391f9;
        color: #5391f9;
      }
      .#{$prefixCls}__item_ball {
        background: rgba(83, 145, 249, 0.2);
        border-color: #5391f9;
        color: #5391f9;
      }
    }
    &:nth-of-type(4) {
      .#{$prefixCls}__item_text {
        color: #0652ff;
      }
      .#{$prefixCls}__item_ball {
        background: rgba(83, 145, 249, 0.2);
        border-color: #2467ff;
        color: #2467ff;
      }
    }
  }
  &__footer {
    margin-bottom: 70px;
    .hai-button:nth-child(1) {
      margin-right: 28px;
      span {
        color: #fff;
      }
    }
  }
}
</style>
