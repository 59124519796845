<template>
  <div :class="prefixCls">
    <label :class="prefixCls + '__label'">
      <input
        type="file"
        :class="[prefixCls + '__input']"
        @change="onChangeFile"
      />
      <img
        :class="prefixCls + '__img'"
        src="../../assets/images/upload-icon.png"
        alt=""
      />
      <p :class="prefixCls + '__text'">{{ $t('haiUpload.title') }}</p>
    </label>
    <span :class="prefixCls + '__tip'">{{ $t('haiUpload.text') }}</span>
  </div>
</template>

<script>
const prefixCls = 'hai-upload'
export default {
  name: 'HaiUpload',
  props: {
    maxSize: {
      type: Number,
      default: 0
    },
    accept: {
      type: Array,
      default: () => ['.WAV', '.MP3', '.AIFF']
    }
  },
  data() {
    return {
      prefixCls
    }
  },

  methods: {
    onChangeFile(event) {
      const file = event.target.files[0]
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.'))
      if (!file) return
      if (
        this.accept.length &&
        !this.accept.includes(fileSuffix.toLocaleUpperCase())
      ) {
        this.$message({
          text: this.$t('haiUpload.errorMsg', { msg: this.accept.join('/') })
        })
        return
      } else if (this.maxSize && file.size / 1024 / 1024 >= this.maxSize) {
        this.$message({
          text: this.$t('haiUpload.errorSizeMsg', { size: this.maxSize })
        })
        return
      }
      this.$emit('onChange', file)
      event.target.value = ''
    }
  }
}
</script>

<style lang="scss">
.hai-upload {
  position: relative;
  &__label {
    width: 370px;
    height: 220px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../assets/images/Rectangle-5.png');
    background-color: #030e2f;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &__img {
    width: 120px;
    height: 120px;
  }
  &__input {
    display: none;
  }
  &__text {
    font-size: 24px;
    color: #5391f9;
    margin-top: 4px;
    text-align: center;
  }
  &__tip {
    position: absolute;
    left: 50%;
    bottom: -50px;
    transform: translateX(-50%);
    font-size: 12px;
    color: #fff;
    opacity: 0.45;
    display: inline-block;
    white-space: nowrap;
  }
}
</style>
