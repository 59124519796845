<template>
  <div :class="$style.container">
    <div :class="$style.dialog">
      <!-- <HaiLogo :class="$style['dialog-left']"></HaiLogo> -->
      <h2 :class="$style['dialog-right']" >
        <span>【{{ title }}】</span>
      </h2>
    </div>
    <main :class="$style.content">
      <slot />
    </main>
    <!-- <footer :class="$style.footer">
      <img src="@/assets/images/footer.png" alt="页脚" />
    </footer> -->
    <!-- <HaiFooter></HaiFooter> -->
    <!-- <i
      v-if="isNextPage"
      ref="leftArrow"
      :class="$style['left-arrow']"
      @click="$router.go(-1)"
    ></i> -->
  </div>
</template>

<script>
// import HaiLogo from '@/components/HaiLogo'
// import HaiFooter from '@/components/HaiFooter'
export default {
  name: 'Layout',
  components: {
    // HaiLogo,
    // HaiFooter
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      marginLeft: 0
    }
  },
  computed: {
    handleMarginLeft() {
      return {
        marginLeft: `${this.marginLeft}px`
      }
    },
    isNextPage() {
      return this.$route.name !== 'home'
    }
  },
  mounted() {
    console.log(this)
    this.initStyle()
    const r = window.screen.availWidth / 1920
    if (this.$refs.leftArrow) {
      this.$refs.leftArrow.style.Transform = 'scale(' + r + ')'
    }
    window.addEventListener('resize', this.initStyle)
  },
  methods: {
    initStyle() {
      this.marginLeft = 80 + window.document.body.clientWidth / 1920
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.dialog {
  height: 168px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding-top: 120px;
  margin: 0 auto;
  &-left {
    position: absolute;
    left: 0;
  }
  &-right {
 
    border-radius: 50px;

    height: 102px;
    font-size: 54px;
    font-weight: 600;
    color: #3a577d;
    line-height: 100px;
    letter-spacing: 1px;
    text-align: center;
    position: relative;
letter-spacing:20px;
    &::before {
      content: '';
      width: 98.5%;
      height: 90px;
      border-radius: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    span {
      z-index: 2;
      position: relative;
    }
  }
}
.content {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  // align-items: center;
  height: 100%;
  overflow: hidden;
}
.footer {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-shrink: 0;
  > img {
    width: 279px;
    height: 30px;
  }
}
.left-arrow {
  background: url('~@/assets/images/left.png') no-repeat center;
  width: 40px;
  height: 40px;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  left: 98px;
  opacity: 0.5;
  top: 46.9%;
  &:hover {
    opacity: 1;
  }
}
</style>
